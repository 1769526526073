import React, {useEffect} from "react";
import Header from "./components/header/Header.js";
import Home from "./components/header/Home.js";
import SimplissimePage from "./components/main/carousel/Simplissime.js";
import ThermomixPage from "./components/main/carousel/Thermomix.js";
import MadMaxPage from "./components/main/carousel/MadMax.js";
import FemmesPage from "./components/main/carousel/Femmes.js";
import LesPetitesPage from "./components/main/carousel/LesPetites.js";
import MarmitonPage from "./components/main/carousel/Marmiton.js";
import CuisineMonPerePage from "./components/main/carousel/CuisineMonPere.js";
import ArtColoriagesPage from "./components/main/carousel/ArtColoriages.js";
import CereliaPage from "./components/main/carousel/Cerelia.js";
import PrehistoirePage from "./components/main/carousel/Prehistoire.js";
import IntemporelsPage from "./components/main/carousel/Intemporels.js";
import HaussmannPage from "./components/main/carousel/Haussmann.js";
import CuisineTitesPoulesPage from "./components/main/carousel/CuisineTitesPoules.js";
import MikoPage from "./components/main/carousel/Miko.js";
import TrousselierPage from "./components/main/carousel/Trousselier.js";
import PrenomsPage from "./components/main/carousel/Prenoms.js";
import EnTrainPage from "./components/main/carousel/EnTrain.js";
import ThermomixPoissonPage from "./components/main/carousel/ThermomixPoisson.js";
import BethlehemPage from "./components/main/carousel/Bethlehem.js";
import ArtColoriagesXX from "./components/main/carousel/ArtColoriages_xx.js";
import MonPetitPage from "./components/main/carousel/MonPetit.js";
import EnquetePage from "./components/main/carousel/EnquetePage.js";
import ThermomixInternational from "./components/main/carousel/ThermomixInternational.js";
import PetitCahier from "./components/main/carousel/PetitCahier.js";
import ReactGA from 'react-ga';

import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Icon from "./components/main/Icon.js";
import HomeFooter from "./components/footer/HomeFooter.js";
import "./components/Css/Style.css";
import About from "./components/About.js";
import Footer from "./components/footer/Footer.js";
import Contact from "./components/Contact.js";
import RefugesPage from "./components/main/carousel/Refuges.js";
import CucinaPage from "./components/main/carousel/Cucina.js";
import MaisonPage from "./components/main/carousel/MaisonPage.js";



function WebApp () {

  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return(
    <>
    <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/a-propos" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/projects/simplissime" element={<SimplissimePage/>}/>
          <Route path="/projects/thermomix-recettes-petits" element={<ThermomixPage/>}/>
          <Route path="/projects/madMax" element={<MadMaxPage/>}/>
          <Route path="/projects/femmes-débordées" element={<FemmesPage/>}/>
          <Route path="/projects/les-petites-routes" element={<LesPetitesPage/>}/>
          <Route path="/projects/marmiton" element={<MarmitonPage />} />
          <Route path="/projects/cuisine-de-mon-père" element={<CuisineMonPerePage />} />
          <Route path="/projects/art-en-coloriages" element={<ArtColoriagesPage />} />
          <Route path="/projects/cerelia" element={<CereliaPage />} />
          <Route path="/projects/préhistoire" element={<PrehistoirePage/>} />
          <Route path="/projects/intemporels" element={<IntemporelsPage/>} />
          <Route path="/projects/SG_Haussmann" element={<HaussmannPage/>} />
          <Route path="/projects/cuisine-p'tites-poules" element={<CuisineTitesPoulesPage/>} />
          <Route path="/projects/miko" element={<MikoPage />} />
          <Route path="/projects/trousselier" element={<TrousselierPage/>} />
          <Route path="/projects/le-guide-dea-prenoms" element={<PrenomsPage />} />
          <Route path="/projects/enTrain" element={<EnTrainPage/>} />
          <Route path="/projects/thermomix-poisson" element={<ThermomixPoissonPage/>} />
          <Route path="/projects/bethlehem" element={<BethlehemPage/>} />
          <Route path="/projects/art-en-coloriages-xx" element={<ArtColoriagesXX/>} />
          <Route path="/projects/mon-petit-monde" element={<MonPetitPage/>} />
          <Route path="/projects/Enquête-sur-dieu" element={<EnquetePage/>} />
          <Route path="/projects/thermomix-international" element={<ThermomixInternational/>} />
          <Route path="/projects/petits-cahiers-dantan" element={<PetitCahier/>} />
          <Route path="/projects/50-refuges-de-montagne" element={<RefugesPage/>} />
          <Route path="/projects/cucina-povera-hachette-pratique" element={<CucinaPage/>} />
          <Route path="/projects/maison-déglon-coutellerie-déglon" element={<MaisonPage/>} />
        </Routes>
        <Footer />
    </>
  )
}


function App() {
  return (
    <div className="app-container">
      <Router>
        <WebApp />
      </Router>
    </div>
  );
}

export default App;
